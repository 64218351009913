<template>
  <div>
    <div class="additional">
      <div
        class="revolut-placeholder"
        :style="{ backgroundImage: 'url(' + logo + ')' }"
        @click="handleRevolutPayment"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RevolutCheckout from "@revolut/checkout";
import { mapState } from "vuex";
import logo from "./../../assets/revolut-logo.jpg";

export default {
  name: "RevolutUk",
  props: {
    order: {
      type: Object,
      default: null,
    },
    customAmount: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: function () {
        return "EUR";
      },
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    getOptions() {
      const hostname = window.location.hostname;
      const dev = [
        "dev.ozparts.uk",
        "dev.ozparts.eu",
        "localhost",
        "refresh2021.ozparts.eu",
      ];
      const suffix = "eu";
      const isProd = process.env.NODE_ENV === "production";
      const isDev = dev.includes(hostname);
      const urlBase = isProd ? "https://api.ozparts.eu" : "http://localhost";
      const url = `${urlBase}/payments-brakes/revolut/${suffix}`;
      const data = {
        amount: isDev
          ? "0.01"
          : (this.order && this.order.grossamount) || this.customAmount,
        currency: (this.order && this.order.currency.iso) || this.currency,
        region: suffix,
      };

      return {
        url,
        data,
      };
    },
    async handleCustomAmount() {
      const { url, data } = this.getOptions();

      try {
        this.$emit("pending", true);
        const response = await axios.post(url, data);
        RevolutCheckout(response.data.public_id).then((instance) => {
          instance.payWithPopup({
            onSuccess: () => this.$emit("success", { status: "COMPLETED" }),
            onError: (error) => this.$emit("error", error),
          });
        });
      } catch (error) {
        this.$emit("error", error);
      } finally {
        this.$emit("pending", false);
      }
    },
    async handleFixedAmount() {
      const { url, data } = this.getOptions();

      try {
        const response = await axios.post(url, data);
        RevolutCheckout(response.data.public_id).then((instance) => {
          instance.payWithPopup({
            email: this.user.email,
            billingAddress: {
              countryCode: this.order.billcountry,
              city: this.order.billcity,
              postcode: this.order.billzip,
              streetLine1: this.order.billaddressee,
              streetLine2: this.order.billaddress,
            },
            onSuccess: () => this.$emit("success", { status: "COMPLETED" }),
            onError: (error) => this.$emit("error", error),
          });
        });
      } catch (error) {
        this.$emit("error", error);
      }
    },
    async handleRevolutPayment() {
      if (this.customAmount) {
        this.handleCustomAmount();
      } else {
        this.handleFixedAmount();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.additional {
  .additional-header {
    background-color: black;
    color: white;
    padding: 5px 15px;
    font-family: "Michroma", sans-serif;
    font-size: 14px;
    margin: 20px 0;
  }

  .revolut-placeholder {
    height: 50px;
    width: 100%;
    background-size: contain;
    background-position: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
