<template>
  <div class="mt-4">
    <h2 class="mb-6">
      {{ $t("shippingMethodsObj.INPOST.LOCATION") }}:
      {{ inpostName || $t("shippingMethodsObj.INPOST.NONE") }}
    </h2>
    <div v-if="inpostDetails">
      <p>
        <span class="bold">{{ inpostDetails.address.line1 }}</span> <br />
        {{ inpostDetails.address.line2 }} <br />
        {{ inpostDetails.location_description }}
      </p>
    </div>
    <div class="inpost">
      <inpost-geowidget
        ref="inpost"
        config="parcelcollect"
        :language="$i18n.locale"
        :token="token"
      ></inpost-geowidget>
    </div>
  </div>
</template>

<script>
export default {
  name: "InpostWrapper",
  data() {
    return {
      inpostName: "",
      inpostDetails: null,
      token: process.env.VUE_APP_INPOST_TOKEN,
    };
  },
  mounted() {
    this.$refs.inpost.addEventListener("inpost.geowidget.init", (event) => {
      const api = event.detail.api;
      this.$emit("apiReady", api);
      api.addPointSelectedCallback((point) => {
        this.inpostName = point.name;
        this.inpostDetails = point;
        this.$emit("pointSelected", point);
      });
    });
  },
};
</script>

<style scoped>
.inpost {
  margin-top: 20px;
  height: 800px;
}

.bold {
  font-weight: 700;
  font-size: 18px;
}
</style>
