export const PAYU = {
  EUR: {
    ID: process.env.VUE_APP_PAYU_EUR_ID,
    SECRET: process.env.VUE_APP_PAYU_EUR_SECRET,
  },
  PLN: {
    ID: process.env.VUE_APP_PAYU_PLN_ID,
    SECRET: process.env.VUE_APP_PAYU_PLN_SECRET,
  },
  GBP: {
    ID: process.env.VUE_APP_PAYU_GBP_ID,
    SECRET: process.env.VUE_APP_PAYU_GBP_SECRET,
  },
};

export const GOOGLE_UA_EU = process.env.VUE_APP_GOOGLE_UA_EU;
export const GOOGLE_AW_EU = process.env.VUE_APP_GOOGLE_AW_EU;
export const GOOGLE_UA_UK = process.env.VUE_APP_GOOGLE_UA_UK;
export const GOOGLE_GTM_UK = process.env.VUE_APP_GOOGLE_GTM_UK;
export const GOOGLE_GTM_EU = process.env.VUE_APP_GOOGLE_GTM_EU;
