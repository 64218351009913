import { render, staticRenderFns } from "./CustomShipping.vue?vue&type=template&id=7dcd6430&scoped=true&"
import script from "./CustomShipping.vue?vue&type=script&lang=js&"
export * from "./CustomShipping.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_pug@3.0.2_vue-template-compiler@2.6.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcd6430",
  null
  
)

export default component.exports