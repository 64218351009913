<template>
  <div>
    <h2 class="header mb-6">Order Review</h2>
    <summary-item>
      <h2>Shipping Address</h2>
      <div class="font-weight-bold">{{ shippingAddress.addressee }}</div>
      <div>{{ shippingAddress.address }}</div>
      <div>{{ shippingAddress.zip }} {{ shippingAddress.city }}</div>
      <div>{{ shippingAddress.countryname || shippingAddress.country }}</div>
      <div class="my-2">{{ shippingAddress.phone }}</div>
      <div v-if="sendAsComplete" class="font-weight-bold">
        To be send when complete
      </div>
    </summary-item>
    <summary-item>
      <h2>Shipping Method</h2>
      <div>{{ shippingMethod.displayname }}</div>
      <div v-if="shippingMethod._id === 'own'">
        <div>{{ $store.state.customShipping.carrier }}</div>
        <div>{{ $store.state.customShipping.speed }}</div>
        <div
          v-if="$store.state.customShipping.label === 'Customer Account Number'"
        >
          {{ $store.state.customShipping.label }}:
          {{ $store.state.customShipping.labelNumber }}
        </div>
        <div v-else>
          <div>
            {{ $store.state.customShipping.label }}
          </div>
          <div class="text--disabled">Upload</div>
        </div>
      </div>
    </summary-item>
    <summary-item>
      <h2>Payment Method</h2>
      <div class="font-weight-bold">{{ paymentMethod.name }}</div>
      <div class="text--disabled">{{ paymentMethod.detaildescription }}</div>
    </summary-item>
    <summary-item>
      <h2>Additional instructions</h2>
      <div>{{ $store.state.memo }}</div>
    </summary-item>
  </div>
</template>

<script>
import SummaryItem from "@/components/checkout/SummaryItem";

export default {
  name: "Confirmation",
  components: {
    SummaryItem,
  },
  props: {
    cart: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const findField = (field) =>
      props.options[field].find((item) => item._id === props.cart[field]);

    const shippingAddress = findField("shippingaddress");
    const shippingMethod = findField("shippingmethod");
    const paymentMethod = findField("paymentmethod");

    const sendAsComplete = !!(
      props.cart.backorderquantity && props.cart.sendtogether
    );

    const goTo = () => ctx.root.$router.push({ name: "confirmation" });

    return {
      shippingAddress,
      sendAsComplete,
      shippingMethod,
      paymentMethod,
      goTo,
    };
  },
};
</script>

<style scoped lang="scss">
.header {
  font-size: 24px;
  font-weight: 600;
}
</style>
