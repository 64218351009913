import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "./../store/index";
import ShippingAddress from "@/views/checkout/ShippingAddress";
import ShippingMethod from "@/views/checkout/ShippingMethod";
import Backorder from "@/views/checkout/Backorder";
import PaymentMethod from "@/views/checkout/PaymentMethod";
import Confirmation from "@/views/checkout/Confirmation";
import Summary from "@/views/checkout/Summary";
import Payments from "@/views/checkout/Payments";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    name: "checkout",
    path: "/checkout/:basket",
    component: () =>
      import(
        /* webpackChunkName: "checkout" */ "./../views/checkout/Checkout.vue"
      ),
    meta: { requiresAuth: true },
    children: [
      {
        name: "shipping-address",
        path: "shipping-address",
        component: ShippingAddress,
      },
      {
        name: "backorder",
        path: "backorder",
        component: Backorder,
      },
      {
        name: "shipping-method",
        path: "shipping-method",
        component: ShippingMethod,
      },
      {
        name: "payment-method",
        path: "payment-method",
        component: PaymentMethod,
      },
      {
        name: "payments",
        path: "payments",
        component: Payments,
      },
      {
        path: "summary",
        name: "summary",
        component: Summary,
      },
      {
        path: "confirmation/:status?",
        name: "confirmation",
        component: Confirmation,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "./../views/Login.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "./../views/FAQ.vue"),
  },
  {
    path: "/dummy",
    name: "dummy",
    component: () =>
      import(/* webpackChunkName: "dummy" */ "./../views/Dummy.vue"),
  },
  {
    path: "/cookies-policy",
    name: "cookies",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "./../views/CookiesPolicy.vue"),
  },
  {
    path: "/privacy_policy_5",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "./../views/Privacy.vue"),
  },
  {
    path: "/news/:friendly",
    name: "news-view",
    component: () =>
      import(/* webpackChunkName: "newsItem" */ "./../views/NewsItem.vue"),
  },
  {
    path: "/return-preview/:id",
    name: "return-preview",
    component: () =>
      import(
        /* webpackChunkName: "ReturnPreview" */ "./../views/ReturnPreview.vue"
      ),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "./../views/News.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "./../views/Terms.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/About.vue"),
  },
  {
    path: "/brands/dba",
    name: "brand-dba",
    component: () =>
      import(/* webpackChunkName: "dba" */ "./../views/brands/BrandDBA.vue"),
  },
  {
    path: "/brands/saber",
    name: "brand-saber",
    component: () =>
      import(
        /* webpackChunkName: "saber" */ "./../views/brands/BrandSaber.vue"
      ),
  },
  {
    path: "/brands/xclutch",
    name: "brand-xclutch",
    component: () =>
      import(
        /* webpackChunkName: "xclutch" */ "./../views/brands/BrandXClutch.vue"
      ),
  },
  {
    path: "/brands/xoutback",
    name: "brand-xoutback",
    component: () =>
      import(
        /* webpackChunkName: "xoutback" */ "./../views/brands/BrandXOutback.vue"
      ),
  },
  {
    path: "/brands/xforce",
    name: "brand-xforce",
    component: () =>
      import(
        /* webpackChunkName: "xforce" */ "./../views/brands/BrandXforce.vue"
      ),
  },
  {
    path: "/brands/hawk",
    name: "brand-hawk",
    component: () =>
      import(/* webpackChunkName: "hawk" */ "./../views/brands/BrandHawk.vue"),
  },
  {
    path: "/brands/pedders",
    name: "brand-pedders",
    component: () =>
      import(
        /* webpackChunkName: "pedders" */ "./../views/brands/BrandPedders.vue"
      ),
  },
  {
    path: "/brands/aeroflow",
    name: "brand-aeroflow",
    component: () =>
      import(
        /* webpackChunkName: "aeroflow" */ "./../views/brands/Aeroflow.vue"
      ),
  },
  {
    path: "/brands/turbosmart",
    name: "brand-turbosmart",
    component: () =>
      import(
        /* webpackChunkName: "turbosmart" */ "./../views/brands/BrandTurbosmart.vue"
      ),
  },
  {
    path: "/brands/sdt",
    name: "brand-sdt",
    component: () =>
      import(/* webpackChunkName: "sdt" */ "./../views/brands/Sdt.vue"),
  },
  {
    path: "/brands/black-diamond",
    name: "brand-black-diamond",
    component: () =>
      import(
        /* webpackChunkName: "black-diamond" */ "./../views/brands/BlackDiamond.vue"
      ),
  },
  {
    path: "/brands/ebc",
    name: "brand-ebc",
    component: () =>
      import(/* webpackChunkName: "ebc" */ "./../views/brands/Ebc.vue"),
  },
  {
    path: "/brands/whiteline",
    name: "brand-whiteline",
    component: () =>
      import(
        /* webpackChunkName: "whiteline" */ "./../views/brands/BrandWhiteline.vue"
      ),
  },
  {
    path: "/brands/redarc",
    name: "brand-redarc",
    component: () =>
      import(
        /* webpackChunkName: "redarc" */ "./../views/brands/BrandRedarc.vue"
      ),
  },
  {
    path: "/brands/acl",
    name: "brand-acl",
    component: () =>
      import(/* webpackChunkName: "acl" */ "./../views/brands/BrandACL.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "./../views/Contact.vue"),
  },
  {
    path: "/custom-payments/:amount?/:currency?",
    name: "custom-payments",
    component: () =>
      import(
        /* webpackChunkName: "custom-payments" */ "./../views/CustomPayments.vue"
      ),
  },
  {
    path: "/stock-clearance/:brand?",
    name: "stock-clearance",
    component: () =>
      import(/* webpackChunkName: "catalogue" */ "./../views/Catalogue.vue"),
  },
  {
    path: "/catalogue/:brand?/",
    name: "catalogue",
    component: () =>
      import(/* webpackChunkName: "catalogue" */ "./../views/Catalogue.vue"),
  },
  {
    path: "/resetpassword",
    name: "renew",
    component: () =>
      import(
        /* webpackChunkName: "password" */ "./../views/ChangePassword.vue"
      ),
  },
  {
    path: "/forget",
    name: "forget",
    component: () =>
      import(/* webpackChunkName: "forget" */ "./../views/Forget.vue"),
  },
  {
    path: "/basket",
    name: "basket",
    component: () =>
      import(/* webpackChunkName: "basket" */ "./../views/Basket.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/status",
    name: "status",
    component: () =>
      import(/* webpackChunkName: "status" */ "./../views/Status.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/panel",
    name: "panel",
    component: () =>
      import(/* webpackChunkName: "panel" */ "./../views/Panel.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pricelist",
    name: "pricelist",
    component: () =>
      import(/* webpackChunkName: "pricelist" */ "./../views/PriceList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/history",
    name: "history",
    component: () =>
      import(/* webpackChunkName: "history" */ "./../views/History.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/returns/",
    name: "returns",
    component: () =>
      import(/* webpackChunkName: "returns" */ "./../views/panel/Returns.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/returns/:invoice/:item?",
    name: "returnsForm",
    component: () =>
      import(
        /* webpackChunkName: "returnsForm" */ "./../views/panel/ReturnsForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/order/:id/:status?",
    name: "order",
    component: () =>
      import(/* webpackChunkName: "order" */ "./../views/Order.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/search/:phrase(.*)",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "./../views/Search.vue"),
  },
  {
    path: "/:id(.*)",
    name: "details",
    component: () =>
      import(/* webpackChunkName: "details" */ "./../views/Product.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  const top = { x: 0, y: 0, behavior: "smooth" };
  if (to.hash) {
    return { selector: to.hash, offset: { x: 0, y: 20 } };
  } else if (from.name && from.name.includes("brand")) {
    return top;
  } else if (to.name === "payments") {
    return savedPosition;
  } else {
    return top;
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

router.beforeEach((to, _, next) => {
  // if (to.path !== "/login") {
  //   store.commit("setUrlBeforeLogin", to.path);
  // }
  if (to.path === "/login") {
    next();
  } else if (
    to.meta.requiresAuth &&
    (!store.getters.isUserLogged || !store.getters.isUserCustomer)
  ) {
    next("/login");
  } else {
    if (to.path === "/brands") {
      next("/#brands");
    } else {
      next();
    }
  }
});

export default router;
