<template>
  <router-link :to="{ name }" class="d-flex align-center mt-2 back-link">
    <v-icon color="black">mdi-arrow-left</v-icon
    ><span class="ml-2"><slot></slot></span>
  </router-link>
</template>

<script>
export default {
  name: "BackArrow",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.back-link {
  color: black;
  text-decoration: none;
  font-size: 14px;
}
</style>
