import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=5a98aa7c&scoped=true&"
import script from "./Summary.vue?vue&type=script&lang=js&"
export * from "./Summary.vue?vue&type=script&lang=js&"
import style0 from "./Summary.vue?vue&type=style&index=0&id=5a98aa7c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_pug@3.0.2_vue-template-compiler@2.6.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a98aa7c",
  null
  
)

export default component.exports