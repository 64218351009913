<template>
  <div>
    <div class="backorder-item d-flex flex-column align-center pa-4">
      <div class="name mb-2">{{ product.displayname }}</div>
      <div>
        <picture>
          <source
            v-if="product.item.photos[0]"
            :srcset="product.item.photos[0].url"
            :alt="product.displayname"
          />
          <source
            type="image/webp"
            srcset="../../assets/photo-holder.webp"
            :alt="product.displayname"
          />
          <img src="../../assets/photo-holder.png" :alt="product.displayname" />
        </picture>
      </div>
      <div class="mt-2">{{ product.backorderquantity }} pcs</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackorderItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.backorder-item {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.name {
  font-weight: 600;
  font-size: 16px;
}

img {
  height: 100px;
  width: 100%;
  object-fit: contain;
}
</style>
