<template>
  <v-dialog :value="value" persistent width="300">
    <v-card dark>
      <v-card-text>
        {{ $t("modalLoaderText") }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalLoader",
  props: {
    value: Boolean,
  },
};
</script>
