export const LOCATION = {
  UK: "61e14c6ae4a35a668517a8fe",
  UK_OLD: "5faa7485371aa739b8a08843",
  EU: "5e0dbaba9e33df43f0b3a495",
  MARKETING: "5e9eb8f802a0263b948b3fe2",
  PEDDERS: "61e14c6ae4a35a668517a8fe",
  BRAKES_PL: "62de6196fa2fc522dc692c7e",
};

export const MANUFACTURER = {
  ACS: "5f9bda55dcdf6b0c04f18789",
  DBA: "5f9bda55dcdf6b0c04f1878c",
  PEDDERS: "5f9bda55dcdf6b0c04f1878b",
  XFORCE: "5f9bda55dcdf6b0c04f1878d",
  HAWK: "5f9bda56dcdf6b0c04f18790",
  TURBOSMART: "5f9bda56dcdf6b0c04f18791",
  ACL: "5f9bda56dcdf6b0c04f1878e",
  WHITELINE: "5f9bda55dcdf6b0c04f1878a",
  REDARC: "5f9bdaf85e3dc023c4682867",
  SABER: "60c063843f41452f00573370",
  AEROFLOW: "60c0672c3f41452f00573373",
  EUROSPARES: "5f9bda56dcdf6b0c04f18792",
  OSRAM: "5f9bda56dcdf6b0c04f18793",
  SDT: "62eb6cea1783cd551e45d770",
  BD: "62eb6c781783cd551e45d76f",
  EBC: "62eb6c671783cd551e45d76e",
  OTHER: "5f9bda56dcdf6b0c04f18794",
};

export const MANUFACTURER_STOCK_ACCURACY = {
  ACCURATE: "ACCURATE",
  TRUSTED: "TRUSTED",
  CHAOTIC: "CHAOTIC",
  POSITIVE: "POSITIVE",
};

export const PRODUCT_AVAILABILITY_STATUS = {
  AVAILABLE: "AVAILABLE",
  PARTIALLY_AVAILABLE: "PARTIALLY_AVAILABLE",
  OUT_OF_STOCK: "OUT OF STOCK",
  CONTACT: "CONTACT",
};

export const EMPTY_ERROR = {
  value: false,
  message: "",
};

export const ACTION = {
  GET_CARTS: "getCarts",
  GET_ITEM: "getItem",
  UPDATE_CART: "updateCart",
  UPDATE_ADDRESS: "updateAddress",
  ADD_ORDER: "addOrder",
  GET_TRANSACTIONS: "getTransactions",
  GET_PRICE: "getPrice",
  GET_USER_ITEMS: "favoriteItems",
  SET_PAYMENT_STATUS: "setPaymentStatus",
};

export const BASKET_FIELD = {
  SHIPPING_ADDRESS: "shippingaddress",
  SEND_TOGETHER: "sendtogether",
  SHIPPING_METHOD: "shippingmethod",
  PAYMENT_METHOD: "paymentmethod",
  IS_LABEL_REQUIRED: "reqlabel",
  REFERENCE_NUMBER: "referencenumber",
  CURRENCY: "currency",
  POINT: "point",
};

export const PROMOTION = {
  DEADSTOCK: "624ff3dfc35b9810ec22e3ed",
};
