import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.modal.show || _vm.modal.value || _vm.value,"max-width":"340"},on:{"click:outside":_vm.handleClick,"keydown":_vm.handleClick}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.modal.header || "Error"))]),(_vm.modal.html)?_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.modal.html)}}):_c(VCardText,[_vm._v(_vm._s(_vm.modal.text || _vm.modal.message || _vm.value))]),_c(VCardActions,[_c('div'),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.handleClick}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }