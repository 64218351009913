import { render, staticRenderFns } from "./InpostWrapper.vue?vue&type=template&id=4d7ccacb&scoped=true&"
import script from "./InpostWrapper.vue?vue&type=script&lang=js&"
export * from "./InpostWrapper.vue?vue&type=script&lang=js&"
import style0 from "./InpostWrapper.vue?vue&type=style&index=0&id=4d7ccacb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_pug@3.0.2_vue-template-compiler@2.6.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d7ccacb",
  null
  
)

export default component.exports