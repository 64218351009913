<template>
  <div class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div key="0" class="swiper-slide">
        <brakes-pl />
      </div>
      <div v-for="slide in slides" :key="slide.id" class="swiper-slide">
        <b-container class="override">
          <div class="slide-wrapper">
            <div class="rotating-banner">
              <div class="image">
                <component
                  :is="slide.url ? 'a' : 'div'"
                  :href="slide.url"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img :data-src="slide.full_image_url" class="swiper-lazy" />
                  <div
                    class="swiper-lazy-preloader swiper-lazy-preloader-black"
                  ></div
                ></component>
              </div>
              <div class="text">
                <div class="main">{{ slide.slide_meta.header[lang] }}</div>
                <div class="supplementary">
                  <component
                    :is="slide.url ? 'a' : 'div'"
                    :href="slide.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ slide.slide_meta.subheader[lang] }}</component
                  >
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <div class="controls controls-left">
      <v-icon large class="nav-icon">mdi-chevron-left</v-icon>
    </div>
    <div class="controls controls-right">
      <v-icon large class="nav-icon">mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BrakesPl from "./Slide3";

import { Navigation, Pagination, Autoplay, Lazy } from "swiper";

import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/lazy";

import { getSlides } from "../../services/sliderService";

export default {
  name: "TheSlider",
  components: {
    BrakesPl,
  },
  data() {
    return {
      slides: [],
      swiper: null,
    };
  },
  mounted() {
    getSlides(this.$source)
      .then((res) => {
        this.slides = res.data;
        this.swiper = new Swiper(".swiper", {
          loop: true,
          init: false,
          autoplay: {
            delay: 10000,
          },
          preloadImages: false,
          lazy: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletActiveClass: "p-bullet",
          },
          navigation: {
            nextEl: ".controls-right",
            prevEl: ".controls-left",
          },
          modules: [Navigation, Pagination, Autoplay, Lazy],
        });
      })
      .then(() => {
        this.swiper.init();
      });
  },
  computed: {
    ...mapState(["country", "lang"]),
  },
};
</script>

<style lang="scss">
.swiper {
  position: relative;
  height: 600px;

  @media screen and (min-width: 1000px) {
    height: 417px;
  }
}

.controls {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-left {
    left: 10px;
  }

  &-right {
    right: 10px;
  }
}

.slide {
  flex: 1 0 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.override {
  padding: 0 20px;
}

.rotating-banner {
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1000px) {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 610px auto;
  }

  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0);
  }

  .image {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    &:has(a):hover {
      cursor: pointer;
    }
  }

  .text {
    width: 65%;
    padding-top: 50px;
    text-transform: uppercase;
    font-weight: normal;

    .main {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .supplementary {
      font-size: 13px;
      width: 65%;
      padding-bottom: 10px;
      border-bottom: 4px solid var(--red);

      a {
        color: black;

        &:hover {
          text-decoration: none;
        }
      }

      &:has(a):after {
        content: ">>";
        margin-left: 5px;
      }
    }
  }
}
</style>

<style>
.p-bullet {
  background-color: var(--red);
  opacity: 1;
}

.swiper-pagination {
  bottom: 40px !important;
}
</style>
