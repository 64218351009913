<template>
  <div class="icons">
    <mobile-icon
      v-if="isUserCustomer"
      text="Profile"
      to="/panel"
      icon="mdi-account"
    />
    <mobile-icon
      v-if="!isUserLogged"
      text="Login"
      to="/login"
      icon="mdi-account"
    />
    <mobile-icon
      v-if="isUserCustomer"
      text="Basket"
      to="/basket"
      icon="mdi-cart"
    ></mobile-icon>
    <mobile-icon
      text="Catalogue"
      to="/catalogue"
      icon="mdi-book-open"
    ></mobile-icon>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileIcon from "./MobileIcon";

export default {
  name: "MobileIcons",
  components: { MobileIcon },
  methods: {
    logout() {
      this.$store.commit("logOut");
      if (this.$route.name !== "home") {
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "isUserLogged",
      "getBasketCount",
      "isUserCustomer",
    ]),
  },
};
</script>

<style scoped lang="scss">
.icons {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
}
</style>
