import axios from "axios";
import { ACTION, LOCATION } from "../vars/vars";

const source = "shopbrakespl";

let languageInterceptor;
let tokenInterceptor;
let sourceInterceptor;

const instance = axios.create({
  baseURL: "https://3cerp.eu/api/external",
});

sourceInterceptor = instance.interceptors.request.use(
  function (config) {
    if (config.data) {
      config.data.source = source;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const configureInstance = (token) => {
  if (tokenInterceptor) {
    instance.interceptors.request.eject(tokenInterceptor);
  }
  tokenInterceptor = instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

export const setLanguage = (language) => {
  if (languageInterceptor) {
    instance.interceptors.request.eject(languageInterceptor);
  }
  languageInterceptor = instance.interceptors.request.use(
    function (config) {
      if (config.data) {
        config.data.language = language === "pl" ? "polish" : "english";
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

export const getUserItems = async () =>
  await instance.post("/transactions", {
    action: ACTION.GET_USER_ITEMS,
  });

export const getReturns = async () =>
  await instance.post("/transactions", {
    action: ACTION.GET_TRANSACTIONS,
    collection: "deliveries",
  });

export const getSingleReturn = async (id) =>
  await instance.post("/transactions", {
    action: ACTION.GET_TRANSACTIONS,
    collection: "deliveries",
    transaction_id: id,
  });

export const getCatalogueManager =
  (manufacturer, additionalHardcodedFields = {}) =>
  (fields = {}) =>
    instance.post("https://ozparts2.usermd.net/api/external/applications", {
      action: "getApplications",
      manufacturergroup: manufacturer,
      ...additionalHardcodedFields,
      ...fields,
    });

export const placeOrder = async (cart_id) =>
  await instance.post("/cart", {
    action: ACTION.ADD_ORDER,
    cart_id,
  });

export const confirmPayment = async (orderId, paymentMethod) =>
  await instance.post("/cart", {
    action: ACTION.SET_PAYMENT_STATUS,
    transaction_id: orderId,
    paymentmethod: paymentMethod,
  });

export const uploadFile = async (file) =>
  instance.post("https://3cerp.eu/api/core/files", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const fetchOrder = async (id) =>
  await instance.post("/transactions", {
    action: ACTION.GET_TRANSACTIONS,
    transaction_id: id,
  });

export const getItems = async (items) =>
  await instance.post("/items", {
    action: "getItem",
    record: items,
  });

export const fetchCarts = async () =>
  await instance.post("/cart", {
    action: ACTION.GET_CARTS,
  });

export const updateField = (cart_id, field, value) =>
  instance.post("/cart", {
    action: ACTION.UPDATE_CART,
    field,
    cart_id,
    value,
  });

export const getPrice = (record, quantity) =>
  instance.post("/item", {
    action: ACTION.GET_PRICE,
    record,
    quantity,
  });

export const bulkUpdateField = (cart_id, changes, refresh = false) =>
  instance.post("/cart", {
    action: ACTION.UPDATE_CART,
    cart_id,
    refresh,
    changes,
  });

export const updateAddress = (address) =>
  instance.post("/cart", {
    action: ACTION.UPDATE_ADDRESS,
    ...address,
  });

export const getURLComponent = (data) =>
  instance.post("/items", {
    action: "getURL",
    keyword: data,
  });

export const getProduct = (record) =>
  instance.post("/items", {
    action: "getItem",
    record,
  });

export const fetchApplications = (item) =>
  instance.post("https://ozparts2.usermd.net/api/external/applications", {
    action: "getApplications",
    item,
  });

export const fetchRelatedItems = (record) =>
  instance.post("https://ozparts2.usermd.net/api/external/applications", {
    action: "getRelatedItems",
    record,
  });

export const fetchStatements = (customer_id, options = {}) =>
  instance({
    url: "https://ozparts2.usermd.net/api/external/statement",
    method: "POST",
    responseType: "arraybuffer",
    data: {
      action: "printTransactions",
      rid: customer_id,
      collection: "users",
      options: { ...options },
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

export const addToCart = (
  record,
  quantity,
  additionalServices,
  memo = "",
  location = LOCATION.BRAKES_PL
) => {
  const data = {
    action: "addToCart",
    quantity,
    record,
    location,
    additionalservice: additionalServices,
  };
  return instance.post("/cart", {
    ...data,
    ...(memo && { memo }),
  });
};

export const addToFavourites = (id) =>
  instance.post("/item", {
    action: "favoriteItems",
    item: id,
    favorite: true,
  });

export const removeFromFavourites = (id) =>
  instance.post("/item", {
    action: "favoriteItems",
    item: id,
    favorite: false,
  });

export const addToWatchdog = (id) =>
  instance.post("/item", {
    action: "favoriteItems",
    item: id,
    notify: true,
  });

export const removeFromWatchdog = (id) =>
  instance.post("/item", {
    action: "favoriteItems",
    item: id,
    notify: false,
  });
