<template>
  <v-app>
    <nav class="white">
      <nav-desktop class="hidden-sm-and-down" />
      <nav-mobile class="hidden-md-and-up" />
    </nav>
    <the-marquee />
    <v-main class="white">
      <router-view />
    </v-main>
    <cookie />
    <deadstock-popup />
    <on-hold-popup />
    <footer-component />
  </v-app>
</template>

<script>
import { provide } from "@vue/composition-api";
import { mapMutations } from "vuex";
import NavDesktop from "./components/navigation/desktop/Desktop";
import NavMobile from "./components/navigation/mobile/Mobile";
import FooterComponent from "./components/Footer";
import Cookie from "./components/Cookie.vue";
import TheMarquee from "./components/banner/TheMarquee.vue";
import OnHoldPopup from "./components/OnHoldPopup.vue";
import DeadstockPopup from "./components/DeadstockPopup.vue";

export default {
  name: "App",
  components: {
    NavDesktop,
    NavMobile,
    FooterComponent,
    DeadstockPopup,
    Cookie,
    TheMarquee,
    OnHoldPopup,
  },
  setup(_, ctx) {
    const source = "shopbrakespl";
    provide("source", source);
  },
  created() {
    this.initLang();
  },
  methods: {
    ...mapMutations(["initLang"]),
  },
};
</script>

<style lang="scss">
@import "./scss/variables.scss";

.v-application {
  font-family: $body-font-family !important;
  font-weight: $body-font-weight !important;
}

body {
  --red: #0055aa;

  line-height: 1.5;
  white-space: normal;
}

.nav-button {
  font-family: "Michroma", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.5em;
  margin: 0 0.4em;
  transition-duration: 0.5s;
  // color: black;

  &:hover {
    background-color: var(--red);
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
  }
}

.nav-active {
  background-color: var(--red);
  color: white;
}

.container {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
