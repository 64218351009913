export default {
  COMMON: {
    CLOSE: "Close",
  },
  CONTACT: {
    GENERAL_FORM: {
      TITLE: "GENERAL ENQUIRIES FORM",
      FIRST_NAME: "First name*",
      LAST_NAME: "Last name*",
      EMAIL: "E-mail*",
      ADDRESS: "Address*",
      ZIP: "Zip*",
      CITY: "City*",
      COUNTRY: "Country*",
      TYPE: "Type of enquiry*",
      TYPES: {
        WITH_PART_NUMBER:
          "Product information requested (technical/price/availability) & I DO know the part number",
        WITHOUT_PART_NUMBER:
          "Product information requested (technical/price/availability) & I DO NOT know the part number",
        SPONSORSHIP: "Sponsorship enquiry",
        OTHER: "Other questions or comments",
      },
      MESSAGE: "Comment / Message*",
      BRAND: "Brand*",
      MAKE: "Make*",
      MODEL: "Model*",
      YEAR: "Year*",
      ENGINE: "Engine*",
      PART_NUMBER: "Part number*",
      PRIVACY_POLICY: "I agree with",
      PRIVACY_POLICY_TEXT: "Privacy Policy*",
      OBLIGATORY: "* - obligatory field",
      SEND: "Send Enquiry Form",
      REQUIRED: "This field is required",
      INVALID_FORMAT: "Invalid format",
    },
    DEALER_FORM: {
      TITLE: "DEALER / DISTRIBUTOR ENQUIRY FORM",
      FIRST_NAME: "First name*",
      LAST_NAME: "Last name*",
      EMAIL: "E-mail*",
      COMPANY: "Name of the company*",
      PHONE: "Name of the company*",
      ADDRESS: "Billing Address*",
      DELIVERY_ADDRESS: "Delivery address (if different)",
      VAT: "VAT number*",
      ZIP: "Zip*",
      CITY: "City*",
      COUNTRY: "Country*",
      MESSAGE: "Comment / Message*",
      SUBTITLE:
        "By answering following voluntary questions you can help us deliver a tailor-made service to you:",
      WHO: "Are you a wholesaler/retailer/specialised garage or other?",
      WHOS: {
        WHOLESALER: "Wholesaler",
        RETAILER: "Retailer",
        GARAGE: "Specialised garage",
        OTHER: "Other",
      },
      CUSTOMER:
        "Are your customers individual car owners or garages and retail outlets?",
      CUSTOMERS: {
        INDIVIDUAL: "Individual car owners",
        GARAGE: "Garages and retail outlets",
      },
      WEBSITE: "Do you have an online shop (if such - what is the www)?",
      SPECIALISATION:
        "Do you have any specialisation (vehicle makes or certain parts only)?",
      RANGE:
        "Do you service local area/entire country/sell outside of country?",
      RANGES: {
        LOCAL: "Local area",
        COUNTRY: "Entire country",
        GLOBAL: "Sell outside of country",
      },
      STOCK: "Do you plan to stock products in your warehouse?",
      STOCKS: {
        YES: "Yes",
        NO: "No",
      },
      PRIVACY_POLICY: "I agree with",
      PRIVACY_POLICY_TEXT: "Privacy Policy*",
      OBLIGATORY: "* - obligatory field",
      SEND: "Send Enquiry Form",
      REQUIRED: "This field is required",
      INVALID_FORMAT: "Invalid format",
    },
    FORM_SUBMITTED: {
      TITLE: "Contact Form Submitted",
      TEXT: "We endeavour to respond within 24 hours",
    },
  },
  ON_HOLD_POPUP: {
    HEADER: "Account on hold",
    TEXT: "Please note, that your account is currently on hold due to overdue invoices/exceeding your credit limit. All open orders are being currently put on hold and will be dispatched only after receiving your payment",
    BUTTON: "To my overdue invoices",
  },
  DEADSTOCK_POPUP: {
    BIG: "big",
    STOCK: "stock",
    CLEARANCE: "clearance",
    UP_TO: "up to",
    BUY_NOW: "buy now",
    OFF: "off",
  },
  customPayments: {
    h1: "Custom Payments",
    header: "Please put desired amount and click the payment widget",
    success: "Payment successful",
    amount: "Amount",
  },
  modalLoaderText: "Please stand by",
  pricelistView: {
    chooseFileFormat: "Choose file format",
    pleaseStandBy: "Please stand by",
    header: "Price Lists",
    back: "BACK TO ACCOUNT",
    subHeader: "Select vendor to download the CSV or Excel file",
    priceList: "Price list",
    itemsDataPack: "Items Data Pack",
    stocklist: "Stock List",
    stocklistHeader:
      "This is stock link allowing you to have always up to date stock information",
  },
  bannerSlide: {
    productRange: "Product Range",
    readMore: "Read More",
    aeroflowOnBoard: "Aeroflow On Board",
    checkOut: "Check Out",
    saberInStock: "Saber In Stock",
    cheaperSaberInStock: "SABER NEW LOWER PRICES",
    deadstock: "BRAKES PL<br /> Stock Clearance",
  },
  deadstock: "Stock clearance",
  catalogues: {
    header: "BRAKES PL ONLINE CATALOGUES",
    brands: "Select Catalogue by brand",
    brandsDeadstock: "Select Stock Clearance Catalogue by brand",
    pickups: "Pickup / Commercial catalogue",
    make: "Make",
    model: "Model",
    year: "Year",
    engine: "Engine",
    variant: "Variant",
    group: "Group",
    category: "Category",
    addToCart: "Add to cart",
    inStock: "In stock",
    inTransit: "In transit",
    deliveryIn: "DELIVERY IN 2-3 WEEKS (SINCE ORDER DAY)",
    readyToOrder: "Ready to order",
    conversionKits: "Conversion Kits",
    transmission: "Transmission",
    discSplineCount: "Disc spline count",
    discSplineDiameter: "Disc spline diameter",
    search: "Search",
    itemAdded: "Item added",
    close: "Close",
    motorsportPartSearch: "Motorsport Part Search",
    caliperSize: "Caliper size",
    universalParts: "Universal Parts",
    class: "Class",
    findUniversalParts: "Find Universal Parts",
    selectGroup: "Select Group",
    checkAvailability: "Check on the Product Page",
  },
  watchdogs: {
    header: "Favourite & Watched",
    watched: "Watched",
    favourite: "Favourite",
    delete: "Delete",
    add: "Add to cart",
    inStock: "In stock",
  },
  order: {
    order: "Order",
    header: "Order Review",
    paid: "Order has been paid",
    generalInformation: "General information",
    orderNumber: "Order Number",
    orderDate: "Order Date",
    terms: "Terms",
    type: "Type",
    shippingAddress: "Shipping Address",
    billingAddress: "Billing Address",
    shippingMethod: "Shipping Method",
    uploadLabel: "Select Shipping Label File",
    addShippingLabel: "Add To Labels",
    uploadShippingLabel: "Upload Shipping Labels",
    uploadedFile: "Uploaded file",
    additionalInstructions: "Additional instructions",
    noAdditionalInstructions: "No additional instructions",
    trackingNumbers: "Tracking numbers",
    number: "Number",
    weight: "Weight",
    waitingForAnUpdate: "Waiting for an update",
    payByPayPal: "Pay by PayPal",
    payByRevolut: "Pay by credit card using Revolut",
    payByPayU: "Pay by PayU",
    payByBankRevolut: "Pay by Bank Transfer (Revolut)",
    for: "For",
    accountNumber: "Account Number",
    sortCode: "Sort Code",
    payByBankTransfer: "Pay by Bank Transfer",
    basket: "Shopping Cart",
  },
  summary: {
    referenceNumber: "Order Number",
    header: "Order Review",
    shippingAddress: "Shipping Address",
    backorder: "Backorder Items",
    sendTogether: "Send all items when available as one shipment",
    sendNow:
      "Send only available items now and those not available send later in a separate shipment",
    shippingMethod: "Shipping Method",
    labelLater: "Label to be uploaded on the next step",
    paymentMethod: "Payment Method",
    additionalInstructions: "Additional instructions",
    noAdditionalInstructions: "No additional instructions",
    placeOrder: "Place my order",
    back: "Return to Payment Methods",
  },
  paymentMethods: {
    header: "Payment Methods",
    continueToSummary: "Continue to summary",
    back: "Return to Shipping Methods",
    referenceNumberHeader: "Order Number (optional)",
    referenceNumber: "Order Number",
  },
  shippingMethodsObj: {
    INPOST: {
      LOCATION: "Chosen location",
      NONE: "None",
    },
    custom: {
      selectCarrier: "Select Carrier",
      selectMethod: "Select Method",
      details: "Details",
      label: "Enter your account number in chosen company",
    },
    shippingMethods: "Shipping Methods",
    additionalInstructions: "Additional instructions",
    instruction:
      "If you have any requests regarding processing this order (for example you wish us to keep this order for further shipment) or instructions regarding packing (for example you wish us to send the parcel with blank tape) etc. please let us know.",
    continueToPaymentMethods: "Continue to payment methods",
    backToBackorder: "Return to Backorder Items",
    backToShippingAddress: "Return to Shipping Address",
    other: "Other",
    economy: "Economy",
    express: "Express",
    customerAccountNumber: "Customer Account Number",
    shippingLabel: "Shipping Label",
  },
  backorder: {
    header: "Backorder items",
    instruction:
      "Following items ordered by you are currently out of stock. Please confirm how you wish us to process the order",
    sendSeparately:
      "Send only available items now and those not available send later in a separate shipment (please note additional charges for the freight cost might apply in case of more than one shipment).",
    sendTogether: "Send all items when available as one shipment.",
    continueToShippingMethods: "Continue to Shipping Methods",
    backToShippingAddress: "Return to Shipping Address",
  },
  stepper: {
    first: "Shipping & Payment methods",
    second: "Summary",
    third: "Billing & Confirmation",
  },
  basketObj: {
    empty: "Your Shopping Cart is Empty",
    yourItems: "Your items",
    proceedToCheckout: "Proceed to checkout",
    continueShopping: "Continue Shopping",
    discountCodeChanged: "Discount code changed",
    close: "Close",
    qty: "Qty",
    remove: "Remove",
    orderSummary: "Order Summary",
    subtotal: "Subtotal",
    items: "items",
    shippingCost: "Shipping Cost",
    paymentMethodFee: "Payment Method Fee",
    airfreightServices: "Airfreight Services",
    tax: "Tax",
    total: "Total",
    availableWeight: "Order weight for available parts",
    backorderWeight: "Backorder weight",
    totalWeight: "Total weight",
    kgs: "kgs",
  },
  sideBasket: {
    header: "Order Details",
    headerReturn: "Return Details",
  },
  shippingAddress: {
    shippingAddress: "Shipping address",
    addNewAddress: "Add new address",
    editAddress: "Edit selected address",
    continue: "Continue",
    placeWithDefault: "Place with Default Settings",
    selectAddress: "Select address",
    label: "Label",
    dispatchDetails: "Email address for informing about order dispatch",
    name: "Name",
    address: "Address",
    country: "Country",
    phone: "Phone number",
  },
  checkout: {
    showEmailField: "Shipping notification for the receiver",
    header: "Checkout",
  },
  units: {
    week: "weeks",
    day: "days",
  },
  returns: {
    success: "Return Registered",
    successText: "Return {name} registered",
    details: "Please clarify",
    quantity: "Quantity",
    totalAmount: "Total Amount",
    return: "Return",
    specialOrderItem: "Special order item - product is non-returnable",
    header: "Please enter the part number you wish to return",
    subheader:
      "Please, generate a return form, print it out and include it in the parcel together with items you wish to return.",
    button: "Generate and print",
    placeholder: "Eg. DBA 650S",
    back: "BACK TO ACCOUNT",
    backToReturns: "BACK TO RETURNS",
    date: "Date",
    number: "Number",
    type: "Type",
    selectProductsToReturn: "Select products to return",
    reason: "Reason",
    from: "FROM",
    to: "TO",
    filter: "FILTER",
    reasons: {
      doesNotFit: "Item does not fit my car",
      doesNotNeed: "Item not needed anymore",
      mistake: "Item ordered incorrectly",
      damaged: "Item arrived not complete / damaged",
      wrongDescription: "Item does not match its description",
      other: "Other",
    },
    table: {
      item: "Item",
      quantity: "Quantity",
      invoice: "Invoice",
      date: "Date",
      amount: "Amount",
    },
    sort: {
      date: "Sort By Date",
      number: "Sort By Number",
      amount: "Sort By Amount",
    },
  },
  modal: {
    returnLength: "Please choose at leat one part",
    successTitle: "Success",
    contactFormSubmitted: "Contact form submitted",
  },
  validations: {
    required: "This field is required",
    format: "Invalid format",
  },
  quickForm: {
    make: "Make",
    model: "Model",
    year: "Year",
    engine: "Engine",
    drivingStyle: "Driving style",
    competitionType: "Competition type",
    quickContactForm: "Quick contact form",
    quickContactFormHeader: "Will this part fit my car? Check with us!",
    send: "Send Enquiry",
    drivingStyles: {
      daily: "Daily street use",
      occasional: "Daily street use & occasional track days",
      track: "Track use only",
    },
  },
  superseded: {
    mainButtonText: "This part was superseded by",
    mainButtonTextAlt: "This part will superseded by",
    checkDetails: "Check details",
    modalHeader: "Part Superseded",
  },
  product: {
    CONVERSION: {
      HEADER: "Clutch & Flywheel Setup Sheet",
      CONTENT:
        "In order to provide the most suitable offer for you, please fill {link} and e-mail it together with requested photos to",
      FOOTER: "We will contact you with our offer shortly",
      LINK_TEXT: "this PDF guide",
    },
    WRONG_QTY: "Multiply Qty. {moq}",
    ENGRAVEMENT: "With ECE R90 homologation number",
    DELIVERY_OPTIONS: "Delivery options",
    DELIVERY_OPTIONS_SUBTITLE:
      "Subject to availability in manufacturer's stock.",
    deliveryTime: "delivery time",
    checkDeliveryTime: "Check",
    shippingTableLinkDsc:
      "For products available in our stock, the standard shipping time is 24-48 hours. Delivery time depends on the selected carrier and destination country.",
    leadTime: "lead time",
    dbaType: {
      kit: "2 discs + set of pads",
      disc: "1 brake disc",
      pad: "1 set of pads",
    },
    enquiry: {
      header: "Send An Enquiry",
      message: "Message",
      send: "Send",
    },
    services: {
      choose: "Please choose a service",
      header: "For the parts that are not in Stock, do you wish to choose",
      addWithoutServices: "Add to cart with no additional services",
      addWithServices: "Add to cart with selected services",
    },
    expectedOn: "expected on",
    inTransit: "In transit",
    inUKStock: "In UK stock",
    inUKStockAfter:
      "Available and ready to ship (2-3 days delivery for UK Mainland)",
    inEUStock: "In EU stock",
    inEUStockAfter:
      "Available and ready to ship (2-3 weeks delivery for UK Mainland)",
    checkOutShippingTimes: "Check out shipping times",
    inStock: "In stock",
    noData: "No data from the manufacturer",
    weeksDelivery: "{weeks} {unit} delivery",
    availability: "Availability",
    manStock: "Manufacturer stock",
    inManStock: "In manufacturer's stock",
    inManStockAnonymous: "Ready to order",
    inMarketingStock: "Marketing stock",
    inDelivery: "Available in delivery",
    subjectToManStock: "Subject to availability in the manufacturer's stock",
    pcs: "pcs.",
    rrpFull: "Recommended retail price, Excl. VAT",
    rrp: "RRP",
    oldPrice: "Old Price",
    yourPrice: "Your Price",
    noVat: "excl. VAT",
    forOnePiece: "For one piece",
    moq: "Your price if ordered at least",
    notReturnable: "Non-returnable Item",
    quantity: "Quantity",
    sendAnEnquiry: "Send an enquiry",
    icons: {
      itemNonReturnableTooltip: "Non-returnable/Non-cancellable item",
      inFavourites: "In Favourites",
      addToFavourites: "Add to My Favourites",
      inWatchdog: "Watched for Stock Changes",
      addToWatchdog: "Notify Me When in Stock",
      itemNonReturnable: "Non-returnable Item",
      itemReturnable: "30-day Return Option",
    },
  },
  pricelist: "Price List",
  components: "Contents of Kit",
  showConversionEnquiry: "Send enquiry",
  myAccount: "My Account",
  login: "Login",
  logout: "Logout",
  basket: "Shopping Cart",
  search: "By part number...",
  ourBrands: "Our brands",
  news: "News",
  catalogue: "Catalogue",
  website: "Website",
  newProductsInStock: "New Products In Stock",
  usefulFiles: "Useful Files",
  importantLinks: "Important links",
  usefulFiles1: "Guides / Catalogues",
  usefulFiles2: "Artwork (Logos / Posters / Banners / Brochures )",
  usefulFiles3: "Product images",
  usefulFiles4: "Videos",
  usefulFiles5: "Technical Support / Fitting Instructions",
  youtube: "Youtube Channel",
  applications: "Applications",
  related: "Related items",
  overview: "Overview",
  registeredOffice: "Registered Office",
  billingPurposes: "billing purposes",
  officeAndWarehouse: "Office & Warehouse",
  contactUs: "Contact Us",
  poland: "Poland",
  openingHours: "Opening Hours",
  openingHours1: "The office is opened between 9:00 – 17:00 (CEST/CET), Mo-Fr.",
  openingHours1uk:
    "The office is opened between 8.30 a.m. – 5.00 p.m. Monday to Thursday and 8.30 a.m. – 1.00 p.m. Friday",
  openingHours2:
    "After submitting your inquiry, we endeavour to contact you back within 24 hours",
  banner: "YOUR PERFORMANCE AND 4X4 PARTS DISTRIBUTION WAREHOUSE",
  banner1:
    "We are the manufacturers' European warehouse distributing high-quality Australian and US parts - DBA (Disc Brakes Australia), Xtreme Clutch, Xtreme Outback, Clutch Pro, Xforce, Pedders, Hawk, Turbosmart, Whiteline, ACL Race Series, Redarc, Saber and Aeroflow.",
  readMore: "read more",
  marqueeText:
    "Due to the national holiday, we will be closed on the 11th of November 2020. Please, take it into consideration when placing the orders.",
  addToCart: "Add to Cart",
  noLongerAvl: "No Longer Available",
  additionalServices: "Additional services",
  pcs: "pcs.",
  signIn: "Sign In",
  signInButton: "Sign In",
  returningCustomer: "Returning customer",
  returningCustomerSub: "Sign in below to checkout with an existing account",
  newCustomer: "New customer",
  newCustomerSub:
    "If you are interested in possibility of becoming a customer/dealer/distributor please fill out this form",
  password: "Password",
  forgotPassword: "Forgot password",
  updatePassword: "Update Your Password",
  updatePassword1: "Send new password credentials",
  saveChanges: "Save Changes",
  anonymous: "Anonymous User",
  profile: "Profile",
  defaultShipping: "Default Shipping",
  edit: "Edit",
  addressBook: "Address Book",
  settings: "My settings",
  otherTools: "Other tools",
  editAddress: "Edit address",
  cancel: "Cancel",
  addNewAddress: "Add new address",
  newAddress: "New address",
  seeAllTransactions: "See All Transactions",
  exportPriceList: "Export Data Files",
  orderStatusReport: "Order Status Report",
  noDefaultAddress:
    "No default shipping address. Please use addressbook and add one",
  close: "Close",
  mySettings: "My settings",
  delete: "Delete",
  makeDefaultShipping: "Make Default Shipping",
  defaultShippingAddress: "Default Shipping Address",
  defaultBillingAddress: "Default Billing Address",
  allOrderHistory: "VIEW ALL ORDER HISTORY >",
  myAccount: "My Account",
  recentOrders: "Recent orders",
  vehicleInformationSheets: "Vehicle Information Sheets",
  manOnlineCatalogues: "MANUFACTURERS’ ON-LINE CATALOGUES",
  clearanceOnlineCatalogues: "YOU ARE ON STOCK CLEARANCE CATALOGUE PAGE",
  manOnlineCataloguesLookingFor:
    "DID NOT FIND THE PRODUCT YOU WERE LOOKING FOR?",
  manOnlineCataloguesDsc:
    "We encourage our customers to also check our manufacturer’s catalogues if they did not find the product they were looking for. If you find the product on their catalogues, let us know on b2b@brakes.pl and we will bring the product for you!",
  clearanceOnlineCataloguesDsc:
    "You are on stock clearance online catalogue page. For the full catalogue click {here}.",
  here: "here",
  clearanceBrandCircle: "Stock Clearance Catalogue",
  techDetails: "Tech. info",
  panel: {
    statementTemplate: "Account Statement",
    warranties: "Warranty forms",
    makeReturn: "Returns",
    terms: {
      header: "Terms & Conditions",
      accepted: "accepted",
      declined: "declined",
      status: "Current status",
      accept: "Accept",
      decline: "Decline",
    },
    cooperation: {
      header: "Conditions of cooperation",
      payment: "Payment conditions",
      limit: "Balance limit",
    },
    returns: {
      pdf: "Credit Note",
      header: "My Returns",
      number: "Number",
      date: "Date",
      reason: "Reason",
      status: "Status",
      quantity: "Quantity",
      details: "Details",
    },
  },
  returnPreview: {
    preview: "Return",
    header: "Return Review",
    generalInformation: "General Information",
    returnNumber: "Return Number",
    returnDate: "Return Date",
    shippingAddress: "Shipping Address",
    billingAddress: "Billing Address",
    reason: "Reason",
    DOWNLOAD_PDF: "Download PDF",
    DOWNLOAD_CREDIT_MEMO: "Download Credit Memo",
  },
  history: {
    PAY_OVERDUE: "Make a payment of",
    from: "From",
    to: "To",
    clear: "Clear",
    export: "Export",
    back: "BACK TO ACCOUNT",
    typeToSearch: "By Transaction Number",
    types: {
      allTypes: "All Transactions",
      salesOrder: "Sales Order",
      invoice: "Invoice",
      payment: "Payment",
    },
    sortBy: {
      date: "Sort by Date",
      number: "Sort by Number",
      amount: "Sort by Amount",
      status: "Sort by Status",
      type: "Sort by Type",
    },
  },
  historyTable: {
    number: "Number",
    type: "Type",
    date: "Date",
    amount: "Amount",
    status: "Status",
  },
};
