<template>
  <div>
    <h2 class="mb-6">{{ $t("shippingMethodsObj.shippingMethods") }}</h2>
    <shipping-method-list
      :shipping-methods="options.shippingmethod"
      :cart="cart"
      @shippingMethodUpdated="handleShippingMethodUpdate"
    />
    <inpost-wrapper
      v-if="cart.shippingmethod === '62e261bfdeb93d6ca24d3c8e'"
      @pointSelected="onPointSelect"
    />
    <v-form ref="customShippingForm">
      <custom-shipping
        v-if="cart.shippingmethod === 'own'"
        :validated="validated"
        @isLabelRequired="updateReqLabel"
      />
    </v-form>
    <h2 class="my-6">{{ $t("shippingMethodsObj.additionalInstructions") }}</h2>
    <v-textarea
      color="black"
      outlined
      class="mt-4"
      name="memo"
      persistent-hint
      :placeholder="$t('shippingMethodsObj.instruction')"
      :value="$store.state.memo"
      @input="handleInput"
    ></v-textarea>
    <checkout-button
      class="mt-2"
      :loading="isLoading"
      :disabled="isNextDisabled"
      @click="goTo"
      >{{ $t("shippingMethodsObj.continueToPaymentMethods") }}</checkout-button
    >
    <back-arrow
      :name="backorderItems.length ? 'backorder' : 'shipping-address'"
    >
      {{
        backorderItems.length
          ? $t("shippingMethodsObj.backToBackorder")
          : $t("shippingMethodsObj.backToShippingAddress")
      }}
    </back-arrow>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { BASKET_FIELD } from "../../vars/vars";
import ShippingMethodList from "@/components/checkout/ShippingMethodList";
import CustomShipping from "@/components/checkout/CustomShipping.vue";
import BackArrow from "@/components/checkout/BackArrow.vue";
import InpostWrapper from "@/components/checkout/InpostWrapper.vue";
import CheckoutButton from "@/components/common/CheckoutButton";

export default {
  name: "ShippingMethod",
  components: {
    ShippingMethodList,
    CustomShipping,
    CheckoutButton,
    BackArrow,
    InpostWrapper,
  },
  props: {
    cart: { type: Object, required: true },
    options: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
    backorderItems: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const validated = ref(false);
    const paczkomatName = ref("");
    const customShippingForm = ref();
    const handleInput = (value) => {
      ctx.root.$store.commit("setMemo", value);
    };

    const handleShippingMethodUpdate = (value) => {
      ctx.emit("updateFieldWithValue", {
        field: BASKET_FIELD.SHIPPING_METHOD,
        value,
      });
    };

    const isNextDisabled = computed(() => {
      const isLoading = props.isLoading;
      const noShippingMethodSelected = !props.cart.shippingmethod;
      const isPaczkomatMethodSelected =
        props.cart.shippingmethod === "62e261bfdeb93d6ca24d3c8e";
      const isPaczkomatNameSelected = !!paczkomatName.value;
      const isPaczkomatWithoutNameSelected =
        isPaczkomatMethodSelected && !isPaczkomatNameSelected;
      const isShippingMethodInvalid = !props.options.shippingmethod.some(
        (method) => method._id === props.cart.shippingmethod
      );

      return (
        noShippingMethodSelected ||
        isPaczkomatWithoutNameSelected ||
        isLoading ||
        isShippingMethodInvalid
      );
    });

    const onPointSelect = (point) => {
      paczkomatName.value = point.name;

      ctx.emit("updateFieldWithValue", {
        field: BASKET_FIELD.POINT,
        value: point.name,
      });
    };

    const updateReqLabel = (value) => {
      ctx.emit("updateFieldWithValue", {
        field: BASKET_FIELD.IS_LABEL_REQUIRED,
        value,
      });
    };

    const isValid = computed(() => {
      if (!props.cart.shippingmethod) {
        return false;
      } else if (props.cart.shippingmethod === "own") {
        const base =
          ctx.root.$store.state.customShipping.label &&
          ctx.root.$store.state.customShipping.carrier &&
          ctx.root.$store.state.customShipping.speed;
        if (
          ctx.root.$store.state.customShipping.label ===
          "Customer Account Number"
        ) {
          return !!(base && ctx.root.$store.state.customShipping.labelNumber);
        } else {
          return !!base;
        }
      } else {
        return true;
      }
    });

    const goTo = () => {
      const isFormValid = customShippingForm.value.validate();
      if (isValid && isFormValid) {
        ctx.root.$router.push({
          name: "payment-method",
          params: { basket: ctx.root.$route.params.basket },
        });
      }
    };

    return {
      validated,
      customShippingForm,
      isNextDisabled,
      onPointSelect,
      handleShippingMethodUpdate,
      goTo,
      handleInput,
      updateReqLabel,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
</style>
