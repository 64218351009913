<template>
  <div class="address pa-4">
    <div class="line">
      <div class="label">{{ $t("shippingAddress.label") }}</div>
      <div class="value">{{ addr.addressee }}</div>
    </div>
    <div class="line">
      <div class="label">Email</div>
      <div class="value">{{ user.email }}</div>
    </div>
    <div v-if="addr.email && addr.email !== user.email" class="line">
      <div class="label">{{ $t("shippingAddress.dispatchDetails") }}</div>
      <div class="value">{{ addr.email }}</div>
    </div>
    <div class="line">
      <div class="label">{{ $t("shippingAddress.name") }}</div>
      <div class="value">{{ addr.name }}</div>
    </div>
    <div class="line">
      <div class="label">{{ $t("shippingAddress.address") }}</div>
      <div class="value">{{ addr.address }}</div>
      <div class="value">{{ addr.zip }} {{ addr.city }}</div>
    </div>
    <div class="line">
      <div class="label">{{ $t("shippingAddress.country") }}</div>
      <div class="value">{{ addr.countryname }}</div>
    </div>
    <div class="line">
      <div class="label">{{ $t("shippingAddress.phone") }}</div>
      <div class="value">{{ addr.phone }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    addr: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  color: #4c4c4c;
}

.value {
  color: black;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 300;
}

.line {
  margin-bottom: 8px;
}

.address {
  border: 1px solid gray;
  border-color: rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}
</style>
