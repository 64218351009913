<template>
  <section class="summary-item pa-6 my-2">
    <router-link :to="{ name: target }">
      <v-icon v-if="editable" class="icon">mdi-pencil</v-icon>
    </router-link>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "SummaryItem",
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-item {
  position: relative;
  border: 1px solid gray;
  border-color: rgba(0, 0, 0, 0.42);
  border-radius: 4 px;
}

.icon {
  position: absolute;
  right: 20px;
  top: 20px;

  &:hover {
    cursor: pointer;
  }
}
</style>
