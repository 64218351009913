import { render, staticRenderFns } from "./CheckoutButton.vue?vue&type=template&id=0c5e5549&scoped=true&"
import script from "./CheckoutButton.vue?vue&type=script&lang=js&"
export * from "./CheckoutButton.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutButton.vue?vue&type=style&index=0&id=0c5e5549&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_pug@3.0.2_vue-template-compiler@2.6.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c5e5549",
  null
  
)

export default component.exports