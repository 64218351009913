<template>
  <div>
    <div class="additional">
      <div id="pp"></div>
      <checkout-button
        v-if="user && !user.paymentrequired && !embedOnly"
        gray
        @click="goTo"
        >Pay by external PayPal page</checkout-button
      >
    </div>
    <modal-loader v-model="modalLoader" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CheckoutButton from "@/components/common/CheckoutButton.vue";
import ModalLoader from "@/components/common/ModalLoader.vue";

let pp = null;

export default {
  name: "PayPal",
  components: { ModalLoader, CheckoutButton },
  props: {
    order: {
      type: Object,
      default: null,
    },
    embedOnly: {
      type: Boolean,
      default: false,
    },
    customAmount: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: function () {
        return "EUR";
      },
    },
  },
  data() {
    return {
      link: "",
      modalLoader: false,
      pp: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    currencyCode() {
      return (this.order && this.order.currency.iso) || this.currency;
    },
    payMeLink() {
      const base = "ozparts";
      return `https://www.paypal.com/paypalme/${base}/${
        (this.order && this.order.grossamount) || this.customAmount
      }${this.currencyCode}`;
    },
  },
  watch: {
    async currency(currency) {
      this.$unloadScript(this.link);
      this.link = this.getPurchaseLink(currency);
      await this.$loadScript(this.link);
      this.renderButton();
    },
  },
  async mounted() {
    this.link = this.getPurchaseLink();
    await this.$loadScript(this.link);
    this.renderButton();
  },
  methods: {
    renderButton() {
      this.pp = paypal.Buttons({
        createOrder: (data, actions) => {
          const options = this.generateOptions();
          return actions.order.create(options);
        },
        onApprove: (data, actions) => {
          this.modalLoader = true;
          return actions.order
            .capture()
            .then((details) => {
              this.$emit("success", details);
            })
            .catch((error) => {
              this.$emit("error", error);
            })
            .finally(() => {
              this.modalLoader = false;
            });
        },
      });
      this.pp.render("#pp");
    },
    generateOptions() {
      const dev = [
        "dev.ozparts.uk",
        "dev.ozparts.eu",
        "localhost",
        "refresh2021.ozparts.eu",
      ];
      const value = dev.includes(document.location.hostname)
        ? "0.01"
        : (this.order && this.order.grossamount) || this.customAmount;
      const email_address = (this.user && this.user.email) || "";
      const order = this.order || "";
      const billingAddress = (this.user && this.user.billingaddress) || "";
      const address = {
        address_line_1:
          (order && order.billaddress) ||
          (billingAddress && billingAddress.address) ||
          "",
        address_line_2:
          (order && order.billaddress2) ||
          (billingAddress && billingAddress.address2) ||
          "",
        admin_area_2:
          (order && order.billcity) ||
          (billingAddress && billingAddress.city) ||
          "",
        postal_code:
          (order && order.billzip) ||
          (billingAddress && billingAddress.zip) ||
          "",
        country_code:
          (order && order.billcountry) ||
          (billingAddress && billingAddress.country) ||
          "",
      };

      const payer = {
        email_address,
        address,
      };

      return {
        purchase_units: [
          {
            amount: {
              value,
              currency_code: this.currencyCode,
            },
          },
        ],
        ...(!this.embedOnly && payer),
      };
    },
    goTo() {
      window.open(this.payMeLink);
    },
    getPurchaseLink(currency = "") {
      const token = process.env.VUE_APP_PAYPAL_TOKEN_EU;
      return `https://www.paypal.com/sdk/js?client-id=${token}&currency=${
        currency || this.currencyCode
      }`;
    },
  },
};
</script>

<style lang="scss" scoped>
.additional {
  .additional-header {
    background-color: black;
    color: white;
    padding: 5px 15px;
    font-family: "Michroma", sans-serif;
    font-size: 14px;
    margin: 20px 0;
  }
}
</style>
