import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper mb-4"},[_c(VAutocomplete,{attrs:{"color":"black","outlined":"","hide-details":"","items":_vm.shippingAddresses,"label":_vm.$t('shippingAddress.selectAddress'),"item-text":"addressee","item-value":"_id"},on:{"change":_vm.handleClick},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(data.item.addressee || data.item.name)+" ")]),_c('div',[_vm._v(_vm._s(data.item.address))]),_c('div',[_vm._v(_vm._s(data.item.zip)+" "+_vm._s(data.item.city))]),_c('div',[_vm._v(_vm._s(data.item.countryname || data.item.country))])])]}}]),model:{value:(_vm.selectedAddress),callback:function ($$v) {_vm.selectedAddress=$$v},expression:"selectedAddress"}}),_c(VDialog,{attrs:{"persistent":"","max-width":"320"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Would you like to change order currency? ")]),_c(VCardText,[_vm._v(" It seems that selected delivery address country could use "+_vm._s(_vm.differentCurrency)+" instead of "+_vm._s(_vm.currentCurrency)+". Would you like to change the currency? ")]),_c(VCardActions,[_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.updateAddress(_vm.selectedId, true)}}},[_vm._v(" Yes, change to "+_vm._s(_vm.differentCurrency)+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.updateAddress(_vm.selectedId)}}},[_vm._v(" No ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }