import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import modernizr from "modernizr";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import VueGtag from "vue-gtag";
import i18n from "./i18n";
import LoadScript from "vue-plugin-load-script";
import VueCompositionAPI from "@vue/composition-api";
import VueLettering from "@miii/vue-lettering";
import VueGtm from "@gtm-support/vue2-gtm";
import { configureInstance, setLanguage } from "@/services/api";
import { FontAwesomeIcon } from "./plugins/fontAwesome";
import {
  GOOGLE_AW_EU,
  GOOGLE_UA_EU,
  GOOGLE_UA_UK,
  GOOGLE_GTM_UK,
  GOOGLE_GTM_EU,
} from "./vars/envs";

Vue.component("FontAwesomeIcon", FontAwesomeIcon);

Vue.config.productionTip = false;

const instance = axios.create({
  baseURL: "https://3cerp.eu/api/external",
});

const source = "shopbrakespl";

const googleAnalytics = GOOGLE_UA_EU;

Vue.use(LoadScript);
Vue.use(VueLettering);
Vue.use(
  VueGtag,
  {
    config: { id: googleAnalytics },
    bootstrap: false,
    includes: [
      {
        id: GOOGLE_AW_EU,
        params: {
          send_page_view: false,
        },
      },
    ],
  },
  router
);

const googleGTM = GOOGLE_GTM_EU;

Vue.use(VueGtm, {
  id: googleGTM,
  enabled: true,
  vueRouter: router,
});

instance.interceptors.request.use(
  function (config) {
    if (config.data) {
      config.data.source = source;
      if (store.state.user && store.state.user.token) {
        config.headers = {
          Authorization: `Bearer ${store.state.user.token}`,
        };
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const catalogue = axios.create();

Vue.prototype.$http = instance;
Vue.prototype.$catalogue = catalogue;
Vue.prototype.$source = source;

Vue.use(BootstrapVue);
Vue.use(VueCompositionAPI);

if (store.state.user && store.state.user.token) {
  configureInstance(store.state.user.token);
}

if (store.state.lang) {
  setLanguage(store.state.lang);
}

const v = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://6d624ccdf5b24ef98a74478d98bc2e6a@sentry.io/1889955",
    integrations: [new Integrations.Vue({ v, attachProps: true })],
  });
}
