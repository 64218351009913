import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c(VAutocomplete,{attrs:{"flat":"","no-filter":"","solo":"","background-color":"#F3F3F3","prepend-inner-icon":"mdi-magnify","items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"hide-no-data":"","hide-details":"","item-text":_vm.getName,"item-value":"urlcomponent","label":_vm.searchText},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToSearch.apply(null, arguments)},"click:prepend-inner":_vm.goToSearch},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VListItemAvatar,{attrs:{"width":"50","height":"50","tile":""}},[_c('img',{attrs:{"src":(data.item.photos &&
              data.item.photos.length &&
              data.item.photos[0].url) ||
            'https://ozparts.eu/img/photo-holder.f4760517.png'}})]),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(data.item.oemmanufacturer)}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"cog",on:{"click":_vm.changeMode}},'v-icon',attrs,false),on),[_vm._v(" mdi-cog ")])]}}])},[_c('span',[_vm._v("Change search mode")])]),_c('universal-modal',{attrs:{"modal":_vm.modal},on:{"closeModal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }