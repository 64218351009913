<template>
  <div class="wrapper d-flex justify-space-around flex-wrap">
    <shipping-method
      v-for="method in shippingMethods"
      :key="method._id"
      :shipping-method="method"
      :selected="cart.shippingmethod === method._id"
      @selectShippingMethod="handleClick"
    />
  </div>
</template>

<script>
import ShippingMethod from "@/components/checkout/ShippingMethod";
import { onMounted } from "@vue/composition-api";

export default {
  name: "ShippingMethodList",
  components: { ShippingMethod },
  props: {
    shippingMethods: {
      type: Array,
      default: () => [],
    },
    cart: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const setDefaultIfEmpty = () => {
      if (!props.cart.shippingmethod) {
        const candidate = props.shippingmethod.find((method) => method.default);
        if (candidate) {
          ctx.emit("shippingMethodUpdated", candidate._id);
        }
      }
    };

    onMounted(setDefaultIfEmpty);

    const handleClick = (value) => {
      ctx.emit("shippingMethodUpdated", value);
    };

    return { handleClick };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
