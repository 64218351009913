<template>
  <div class="brands-wrapper">
    <page-header :text="$t('ourBrands')" font="black" />
    <b-container>
      <div class="brand-items">
        <brand-circle
          v-for="brand in brandsFiltered"
          :key="brand.header"
          :brand="brand"
          class="brand-item"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BrandCircle from "@/components/brands/BrandCircle.vue";
import PageHeader from "@/components/common/PageHeader.vue";

const dba = require("./../../assets/kolecka/logo-dba-2.png");
const hawk = require("./../../assets/kolecka/logo-hawk-2.png");
const sdt = require("./../../assets/kolecka/logo-sdt-2.png");
const blackDiamond = require("./../../assets/kolecka/logo-bd-2.png");
const ebc = require("./../../assets/kolecka/logo-ebc-2.png");

const dbaWebp = require("./../../assets/kolecka/logo-dba-2.webp");
const hawkWebp = require("./../../assets/kolecka/logo-hawk-2.webp");
const sdtWebp = require("./../../assets/kolecka/logo-sdt-2.webp");
const blackDiamondWebp = require("./../../assets/kolecka/logo-bd-2.webp");
const ebcWebp = require("./../../assets/kolecka/logo-ebc-2.webp");

export default {
  name: "BrandsComponent",
  components: { BrandCircle, PageHeader },
  data: () => {
    return {
      brands: [
        {
          header: "DBA",
          dsc: "Disc Brakes & Pads",
          img: dba,
          imgWebp: dbaWebp,
          to: "/brands/dba",
        },
        {
          header: "HAWK",
          dsc: "Brake Pads",
          img: hawk,
          imgWebp: hawkWebp,
          to: "/brands/hawk",
        },
        {
          header: "SDT",
          dsc: "Performance and Competition Brakes.",
          img: sdt,
          imgWebp: sdtWebp,
          to: "/brands/sdt",
        },
        {
          header: "Black Diamond",
          dsc: "Performance Brakes.",
          img: blackDiamond,
          imgWebp: blackDiamondWebp,
          to: "/brands/black-diamond",
        },
        {
          header: "EBC",
          dsc: "High Performance Brakes.",
          img: ebc,
          imgWebp: ebcWebp,
          to: "/brands/ebc",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    brandsFiltered() {
      return this.brands.filter(
        (brand) =>
          !(brand.hideOn && brand.hideOn.includes(this.$source)) ||
          (brand.header === "PEDDERS" && this.user && this.user.forpricegroups)
      );
    },
  },
};
</script>

<style lang="scss">
.brands-wrapper {
  h2 {
    text-align: center;
    text-transform: uppercase;
    word-spacing: 5px;
  }

  .line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid black;
    margin: 1em auto;
    padding: 0;
    margin-top: 1em;
    width: 10em;
  }

  .brand-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .brand-item {
      width: 200px;
      margin-top: 2em;
      display: flex;
      justify-content: center;

      @media screen and (min-width: 600px) {
        margin: 1em;
      }
    }
  }
}
</style>
