<template>
  <div>
    <h2 class="mb-6">{{ $t("shippingAddress.shippingAddress") }}</h2>
    <shipping-address-list
      :shipping-addresses="options.shippingaddress"
      :cart="cart"
      @updateWithValue="hanldeUpdateWithValue"
    />
    <div class="header">
      <shipping-address-item
        :addr="
          options.shippingaddress.find((addr) => addr._id === selectedAddressId)
        "
        selected
      />
      <div class="d-flex flex-column mt-2 mb-6">
        <checkout-button
          gray
          class="my-2"
          :loading="isLoading"
          :disabled="isLoading"
          @click="newAddress"
          >{{ $t("shippingAddress.addNewAddress") }}</checkout-button
        >
        <checkout-button
          v-if="!isSelectedAddressDefault"
          gray
          :loading="isLoading"
          :disabled="isLoading"
          @click="editAddress"
          >{{ $t("shippingAddress.editAddress") }}</checkout-button
        >
      </div>
    </div>
    <checkout-button
      class="mt-4 btn-checkout white--text"
      :loading="isLoading"
      :disabled="
        !cart.shippingaddress ||
        isLoading ||
        !options.shippingaddress.some(
          (addr) => addr._id === cart.shippingaddress
        )
      "
      @click="goTo"
      >{{ $t("shippingAddress.continue") }}</checkout-button
    >
    <checkout-button
      v-if="hasAllDefaults && !backorderItems.length"
      class="mt-4 btn-checkout white--text"
      :loading="isLoading"
      :disabled="isLoading"
      @click="handleDefault"
      >{{ $t("shippingAddress.placeWithDefault") }}</checkout-button
    >
    <address-form
      v-model="addressForm"
      @ok="handleAddressUpdate"
      @cancel="addressForm.showAddressForm = false"
    />
  </div>
</template>

<script>
import ShippingAddressList from "@/components/checkout/ShippingAddressList";
import ShippingAddressItem from "@/components/checkout/ShippingAddress";
import CheckoutButton from "@/components/common/CheckoutButton";
import AddressForm from "@/components/checkout/AddressForm";
import { ref } from "@vue/composition-api";

const emptyAddress = {
  _id: "",
  email: "",
  addressee: "",
  address: "",
  address2: "",
  zip: "",
  city: "",
  country: "",
  phone: "",
  default: false,
};

export default {
  name: "ShippingAddress",
  components: {
    ShippingAddressList,
    ShippingAddressItem,
    CheckoutButton,
    AddressForm,
  },
  props: {
    cart: { type: Object, default: null },
    options: { type: Object, default: null },
    backorderItems: {
      type: Array,
      default: () => [],
    },
    selectedAddressId: { type: String, default: "" },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasAllDefaults: {
      type: Boolean,
      default: false,
    },
    isSelectedAddressDefault: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const handleDefault = () => ctx.emit("setAllDefaults");

    const addressForm = ref({
      showAddressForm: false,
      ...emptyAddress,
    });

    const newAddress = () => {
      addressForm.value = {
        ...emptyAddress,
        showAddressForm: true,
      };
    };
    const editAddress = () => {
      const candidate = props.options.shippingaddress.find(
        (addr) => addr._id === props.selectedAddressId
      );
      addressForm.value = {
        ...candidate,
        showAddressForm: true,
      };
    };

    const handleAddressUpdate = () => {
      const {
        _id,
        address,
        email,
        address2,
        addressee,
        city,
        country,
        name,
        phone,
        zip,
      } = addressForm.value;
      ctx.emit("updateAddress", {
        _id,
        address,
        email,
        address2,
        addressee,
        city,
        country,
        name,
        phone,
        zip,
      });
      addressForm.value = {
        ...emptyAddress,
        showAddressForm: false,
      };
    };

    const hanldeUpdateWithValue = (changes) =>
      ctx.emit("updateFieldWithChanges", changes);

    const goTo = () => {
      if (props.backorderItems.length) {
        ctx.root.$router.push({
          name: "backorder",
          params: { basket: ctx.root.$route.params.basket },
        });
      } else {
        ctx.root.$router.push({
          name: "shipping-method",
          params: { basket: ctx.root.$route.params.basket },
        });
      }
    };

    const goToSummary = () => {
      ctx.root.$router.push({
        name: "summary",
        params: { basket: ctx.root.$route.params.basket },
      });
    };

    return {
      addressForm,
      handleAddressUpdate,
      newAddress,
      editAddress,
      hanldeUpdateWithValue,
      goTo,
      handleDefault,
    };
  },
};
</script>
