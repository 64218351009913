import { ref, computed } from "@vue/composition-api";

export const useAddressFilter = (shippingAddresses) => {
  const query = ref("");

  const resetQuery = () => (query.value = "");

  const filterShippingAddresses = () => {
    if (query.value) {
      return shippingAddresses.filter((addr) => {
        return (
          (addr.addressee && addr.addressee.includes(query.value)) ||
          (addr.name && addr.name.includes(query.value)) ||
          (addr.address && addr.address.includes(query.value))
        );
      });
    } else {
      return shippingAddresses;
    }
  };

  const filteredShippingAddresses = computed(filterShippingAddresses);

  return {
    query,
    resetQuery,
    filteredShippingAddresses,
  };
};
