import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";

import i18n from "./../i18n";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: ({ catalogue, ...rest }) => rest,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    catalogue: {},
    deadstockPopup: true,
    memo: "",
    customShipping: {
      carrier: "",
      speed: "",
      label: "",
      labelNumber: "",
    },
    lang: "en",
    user: null,
    basketQuantity: 0,
    welcome: false,
    showMobileMenu: false,
    urlBeforeLogin: null,
    cookieConsent: false,
    userItems: [],
    country: "",
  },
  getters: {
    getUser: (state) => state.user,
    isUserLogged: (state) => !!state.user,
    getToken: (state) => state.user && state.user.token,
    getBasketCount: (state) => state.basketQuantity,
    isUserCustomer: (state) =>
      state.user && state.user.role && state.user.role._id === "role_customer",
  },
  mutations: {
    updateCatalogue: (state, payload) =>
      (state.catalogue = { ...state.catalogue, ...payload }),
    updateDeadstockPopup: (state, payload) => (state.deadstockPopup = payload),
    setUserItems: (state, payload) => (state.userItems = payload),
    setMemo: (state, payload) => (state.memo = payload),
    updateCustomShipping(state, payload) {
      state.customShipping = {
        ...state.customShipping,
        ...payload,
      };
    },
    setCountry: (state, payload) => (state.country = payload),
    setAgreement(state, payload) {
      state.user = { ...state.user, agreement: payload };
    },
    setCookieConsent(state, payload) {
      state.cookieConsent = payload;
    },
    initLang(state) {
      if (state.lang && state.cookieConsent) {
        i18n.locale = state.lang;
      }
    },
    setLang(state, payload) {
      if (state.cookieConsent) {
        state.lang = payload;
        i18n.locale = payload;
      }
    },
    setUrlBeforeLogin(state, payload) {
      state.urlBeforeLogin = payload;
    },
    setMobileMenu(state, payload) {
      if (state.cookieConsent) {
        state.showMobileMenu = payload;
      }
    },
    logIn(state, payload) {
      if (state.cookieConsent) {
        state.user = payload;
        state.basketQuantity = payload.quantity;
      }
    },
    logOut(state) {
      state.user = null;
      state.basketQuantity = 0;
      state.userItems = [];
    },
    updateQuantity(state, payload) {
      if (state.cookieConsent) {
        state.basketQuantity = payload;
      }
    },
    prepareMessage(state) {
      state.welcome = true;
    },
    turnOffMessage(state) {
      state.welcome = false;
    },
  },
  actions: {
    checkCountry: async (ctx) => {
      const { data } = await axios.get("https://api.ozparts.eu/country");
      ctx.commit("setCountry", data.code || "PL");
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
