<template>
  <div class="backorder">
    <h2>{{ $t("backorder.header") }}</h2>
    <p>{{ $t("backorder.instruction") }}:</p>
    <v-radio-group v-model="localSendTogether">
      <v-radio
        color="black"
        :label="$t('backorder.sendSeparately')"
        :value="false"
      ></v-radio>
      <v-radio
        color="black"
        :label="$t('backorder.sendTogether')"
        :value="true"
      ></v-radio>
    </v-radio-group>
    <backorder-item-list :backorder-items="backorderItems" />
    <checkout-button
      class="mt-6"
      :loading="isLoading"
      :disabled="isLoading"
      @click="goTo"
      >{{ $t("backorder.continueToShippingMethods") }}</checkout-button
    >
    <back-arrow name="shipping-address">
      {{ $t("backorder.backToShippingAddress") }}
    </back-arrow>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { BASKET_FIELD } from "@/vars/vars";
import BackorderItemList from "@/components/checkout/BackorderItemList.vue";
import BackArrow from "@/components/checkout/BackArrow.vue";
import CheckoutButton from "@/components/common/CheckoutButton";

export default {
  name: "Backorder",
  components: {
    BackorderItemList,
    BackArrow,
    CheckoutButton,
  },
  props: {
    cart: { type: Object, default: null },
    backorderItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const localSendTogether = ref(props.cart.sendtogether || false);

    watch(localSendTogether, (value) =>
      ctx.emit("updateFieldWithValue", {
        field: BASKET_FIELD.SEND_TOGETHER,
        value,
      })
    );

    const goTo = () => {
      ctx.root.$router.push({
        name: "shipping-method",
        params: { basket: ctx.root.$route.params.basket },
      });
    };

    return {
      localSendTogether,
      goTo,
    };
  },
};
</script>

<style lang="scss">
.backorder {
  label {
    margin-bottom: 0;
  }
}
</style>
