<template>
  <div class="nav-desktop">
    <b-container>
      <upper-part />
      <lower-part />
    </b-container>
  </div>
</template>

<script>
import UpperPart from "./UpperPart";
import LowerPart from "./LowerPart";

export default {
  name: "NavDesktop",
  components: { UpperPart, LowerPart },
};
</script>
